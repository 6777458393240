import React from 'react'

const spacing = {
	small: 20,
	medium: 40,
	large: 100,
}

export const cmsId = `spacer`

export default function Spacer(props) {
	props = props.module
	const height = spacing[props.height] || props.customHeight || 0
	return(
		<div className='spacer' style={{ height }} />
	)
}