import React from 'react'
import { Global, css } from '@emotion/core'
import settings from '@data/sanity/constrainWidthSettings.json'

const maxWidth = settings.maxWidth || 960
const padding = settings.padding || 10

const styles = css`
	.constrain{
		max-width: ${maxWidth}px;
		padding: 0 ${padding}px;
		margin-left: auto;
		margin-right: auto;
	}
	.constrain .constrain{
		max-width: none;
		padding: 0;
	}
`

export default children => {
	return (
		<>
			<Global styles={styles} />
			{children}
		</>
	)
}