import React from 'react'
import get from 'lodash/get'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import SanityBlock from '@app/react-sanity-block-content'
import LayoutWrapper from '@app/layout-wrapper'

export const cmsId = `accordion`

export default function AccordionModule(props){
	props = props.module
	const rows = get(props, `rows`, [])
	return(
		<LayoutWrapper>
			<Accordion>
				{rows.map((row, index) => {
					return (
						<AccordionItem key={index}>
							<AccordionItemHeading>
								<AccordionItemButton>
									{row.label}
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<SanityBlock body={get(row, `content.copyBlockContent`)} />
							</AccordionItemPanel>
						</AccordionItem>
					)
				})}
			</Accordion>
		</LayoutWrapper>
	)
}