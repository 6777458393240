import get from 'lodash/get'
import shopifyPlugin from '@data/sanity/shopifyPlugin'
import useShopify from './use-shopify'

const settings = shopifyPlugin.settings || {}

export default function useShopifyCheckout() {
	const shopify = useShopify()
	function checkout(e) {
		if(e && e.preventDefault) e.preventDefault()
		const webUrl = get(shopify, `store.checkout.webUrl`)
		const activeEnv = process.env.NODE_ENV || `development`
		let href = webUrl
		if (activeEnv === `development` && settings.themeId) {
			href = `${href}?fts=0&preview_theme_id=${settings.themeId}`
		}
		window.location.href = href
	}
	return checkout
}