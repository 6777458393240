import React from 'react'
import HubspotForm from './react/hubspot-form'

function HubspotModule(props){
	return(
		<HubspotForm {...props} />
	)
}


export default {
	hubspotForm: HubspotModule,
}