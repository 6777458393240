import { useEffect } from 'react'
import { useUlysses } from '@smarterlabs/ulysses'
import Client from 'shopify-buy'
import shopifyPlugin from '@data/sanity/shopifyPlugin'

const { themeId } = shopifyPlugin.options || {}

export default function ShopifyPlugin(options) {
	const ulysses = useUlysses()
	useEffect(() => {
		const client = (Client && Client.buildClient) ? Client.buildClient(options.client) : {}
		ulysses.client = client

		// Load or create checkout ID
		let checkout

		async function getCheckout(force){
			if (!checkout || force) {
				console.log(`Creating new Shopify checkout...`)
				let newCheckout = await client.checkout.create()
				if(!checkout || force){
					checkout = newCheckout
				}
			}
			return checkout
		}

		async function onUpdateLineItems({ lineItem }){
			if (!Array.isArray(lineItem)) lineItem = [lineItem]
			const items = lineItem.map(item => {
				return {
					id: item.lineItemId,
					quantity: item.quantity,
				}
			})
			checkout = await getCheckout()
			try {
				checkout = await client.checkout.updateLineItems(checkout.id, items)
			}
			catch (err) {
				console.error(err)
				return false
			}
			return true
		}

		async function onAddToCart(items) {
			for (let i = items.length; i--;) {
				if (!items[i].shopifyId) {
					throw `"shopifyId" is required for addToCart method.`
				}
			}
			checkout = await getCheckout()
			try {
				checkout = await client.checkout.addLineItems(checkout.id, items.map(item => {
					return {
						variantId: item.shopifyId,
						quantity: item.quantity,
					}
				}))
				items.forEach(item => {
					checkout.lineItems.forEach(lineItem => {
						if (lineItem.variant.id === item.shopifyId) {
							item.lineItemId = lineItem.id
						}
					})
				})
			}
			catch(err){
				console.error(err)
				return false
			}
			return true
		}



		async function onRemove(item) {
			if (!item.lineItemId) {
				console.error(`"lineItemId" is required for remove method.`)
				return false
			}
			checkout = await getCheckout()
			try {
				checkout = await client.checkout.removeLineItems(checkout.id, [item.lineItemId])
			}
			catch (err) {
				console.error(err)
				return false
			}
			return true
		}

		async function onCheckout() {
			console.log(`Shopify checkout`)
			await ulysses.emit(`checkout:shopify`, { client, checkout })
			let href = checkout.webUrl
			if (options.development) {
				href = `${href}?fts=0&preview_theme_id=${themeId}`
			}
			window.location.href = href
		}

		async function onSaveState({ state }){
			console.log(`Shopify onSaveState`)
			const newCheckout = await getCheckout()
			if (!checkout) {
				checkout = newCheckout
			}
			state.shopifyCheckoutId = checkout.id
		}
		async function onLoadState(args) {
			console.log(`Shopify onLoad State`)
			const { state } = args
			if (!state.shopifyCheckoutId) {
				checkout = await getCheckout()
				args.state.shopifyCheckoutId = checkout.id
				state.lineItems.length = 0
			}
			console.log(`Loading Shopify checkout ${state.shopifyCheckoutId} ...`)
			try {
				checkout = await client.checkout.fetch(state.shopifyCheckoutId)
			}
			catch(err){
				console.log(`Loading Shopify checkout failed`)
				throw err
			}
			if (!checkout || checkout.completedAt){
				console.log(`Shopify user already checked out`)
				checkout = await getCheckout(true)
				args.state.shopifyCheckoutId = checkout.id
				state.lineItems.length = 0
			}
			else{

				console.log(`Loaded Shopify checkout`)
			}
			return true
		}

		ulysses.on(`addToCart`, onAddToCart)
		ulysses.on(`updateLineItems`, onUpdateLineItems)
		ulysses.on(`checkout`, onCheckout)
		ulysses.on(`remove`, onRemove)
		ulysses.on(`saveState`, onSaveState)
		ulysses.on(`loadState`, onLoadState)
	}, [])
	return null
}