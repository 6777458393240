import React from 'react'
import { Formik } from 'formik'
import { css } from '@emotion/core'
import useSearch from '@app/use-search'
import { navigate } from "gatsby"
import Icon from '@meronex/icons/fa/FaSearch'

export const cmsId = `searchInput`

export default function SearchInput(props) {
	props = props.module || {}
	const { setQuery } = useSearch()

	return (
		<div css={styles.wrapper}>
			<Formik
				initialValues={{ query: `` }}
				onSubmit={(values, { setSubmitting }) => {
					setQuery(values.query)
					navigate(`/search/${encodeURIComponent(values.query)}`)
					setSubmitting(false)
				}}
			>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<form onSubmit={handleSubmit} css={styles.form}>
						<input
							type='text'
							name='query'
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={props.placeholder || `Search...`}
							value={values.query}
						/>
						<button
							css={styles.button}
							type="submit"
							disabled={isSubmitting}
						>
							<Icon css={styles.icon} />
						</button>
					</form>
				)}
			</Formik>
		</div>
	)
}

const styles = {
	wrapper: css`
		text-align: center;
		margin: 30px 0;
	`,
	form: css`
		display: inline-block;
		> *{
			display: inline-block;
		}
	`,
	icon: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: .7em;
	`,
	button: css`
		padding: 3px;
		position: relative;
		height: 28px;
		width: 60px;
		min-width: 0;
		min-height: 0;
		margin: 0;
		top: 5px;
	`,
}