import React from 'react'
import Spinner from 'react-spinkit'
import { css } from '@emotion/core'

export default function LoadingAnimation(){
	return(
		<div css={styles.container}>
			<Spinner css={styles.spinner} name="ball-clip-rotate-multiple" />
		</div>
	)
}

const styles = {
	container: css`
		margin: auto;
		width: 100px;
		max-width: 100%;
		height: 100px;
		position: relative;
	`,
	spinner: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,
}