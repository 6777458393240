import React from 'react'
import { css } from '@emotion/core'
import get from 'lodash/get'
import BgImg from '@app/responsive-image-sanity/background'
import Img from '@app/responsive-image-sanity'
import LayoutWrapper from '@app/layout-wrapper'
import SanityBlock from '@app/react-sanity-block-content'
import settings from '@data/sanity/heroSplitSettings.json'
import renderCss from '@app/sanity-style-fields/css'

export default function Hero(props){
	props = props.module
	const copy = get(props, `copyBlockContent`)
	const bg = get(props, `backgroundImage.asset`)
	const rgba = get(props, `backgroundColor.rgb`)
	let background
	if(rgba){
		background = `rgba(${[rgba.r, rgba.g, rgba.b, rgba.a].join(`,`)})`
	}

	return (
		<div className='heroSplit' css={[styles.container, props.position === `reverse` && styles.reverse]}>
			<div css={styles.backgroundContainer}>
				<BgImg asset={bg} />
			</div>
			<div css={styles.copyContainer} style={{ background }}>
				{props.image && (
					<Img css={styles.image} {...props.image} />
				)}
				<div css={styles.copy}>
					<LayoutWrapper>
						{!!copy && (
							<SanityBlock body={copy} />
						)}
					</LayoutWrapper>
				</div>
			</div>
		</div>
	)

}

const breakpoint = 900

const styles = {
	copyContainer: css`
		text-align: center;
		padding-top: 30px;
	`,
	backgroundContainer: css`
		min-height: 50vh;
		position: relative;
		> *{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-size: cover;
			background-position: center;
		}
	`,
	container: css`
		position: relative;
		background-size: cover;
		background-position: center;
		> *{
			display: block;
		}
		@media(min-width: ${breakpoint}px){
			display: flex;
			flex-direction: row;
			> *{
				width: 50%;
			}
		}
		${renderCss(settings)}
	`,
	reverse: css`
		@media(min-width: ${breakpoint}px){
			> *{
				:first-of-type{
					order: 2;
				}
			}
		}
	`,
	containerLink: css`
		text-decoration: none;
	`,
	image: css`
		max-width: 200px;
		position: relative;
		margin-top: -100px;
		@media(min-width: ${breakpoint}px){
			margin-top: 0;
		}
	`,
	copy: css`
		text-align: center;
		padding: 30px 0;
		color: #fff;
	`,
}