import React from 'react'
import { css } from '@emotion/core'
import Img from '@app/responsive-image-sanity'

export default function BrandLinks(props){
	const maxWidth = `${100 / props.brands.length}%`

	return (
		<div css={styles.container}>
			{props.brands.map((brand, key) => {
				return (
					<a
						key={key}
						target='_blank'
						rel='noopener noreferrer'
						href={brand.link}
						css={styles.brandLink}
						style={{ maxWidth }}
					>
						<Img alt={brand.title} maxWidth={70} {...brand.image} />
					</a>
				)
			})}
		</div>
	)
}

const styles = {
	brandLink: css`
		padding: 10px;
		display: inline-block;
		:hover{
			opacity: .7;
		}
	`,
}