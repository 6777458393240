import React, { useState } from 'react'
import { css } from '@emotion/core'
import siteSettings from '@data/sanity/siteSettings'
import headerData from '@data/sanity/wcHeader.json'
import settings from '@data/sanity/wcHeaderSettings.json'
import get from 'lodash/get'
import NavMenu from './nav-menu'
import LayoutWrapper from '@app/layout-wrapper'
import ClickOutside from '@app/detect-click-outside'
import SanityImg from '@app/responsive-image-sanity'
import DynamicLink from '@app/react-sanity-dynamic-link'
import SocialButtons from './social-buttons'
import { renderStyles } from '@app/sanity-style-fields/css'
import SanityBlock from '@app/react-sanity-block-content'
import CartIcon from './cart-icon'
import BrandsBanner from './brands-banner'

const logo = headerData.logo || {}
const logoLink = logo.link || `/`
const logoImg = get(logo, `image.asset`)

const barIconLinks = headerData.barIconLinks || []
const links = headerData.links || []
const brandsBanner = headerData.brandsBanner || {}
const navIcon = settings.navIcon || {}
const defaultNavIcon = get(navIcon, `icon.svg`)
const activeNavIcon = get(navIcon, `activeIcon.svg`, defaultNavIcon)

const navLinkStyles = settings.navLinkStyles || []

export default function Navigation() {
	const [isNavOpen, setIsNavOpen] = useState(false)

	function toggleNavClick(e){
		e.preventDefault()
		setIsNavOpen(!isNavOpen)
	}

	return <>
		<BrandsBanner {...brandsBanner} />
		<ClickOutside onClick={() => setIsNavOpen(false)}>
			<header css={styles.header}>
				<LayoutWrapper>
					<div css={styles.row}>
						<div css={styles.logo}>
							<DynamicLink link={logoLink}>
								{!!logoImg && (
									<SanityImg
										asset={logoImg}
										placeholder={false}
										alt={`${siteSettings.title} logo`}
										width={140}
										height={35}
									/>
								)}
								{!logoImg && siteSettings.title}
							</DynamicLink>
						</div>
						{barIconLinks.map(barIcon => {
							const slug = get(barIcon, `link.internalLink.slug.current`)
							return (
								<DynamicLink
									link={barIcon.link}
									key={barIcon._key}
									css={styles.barIcon}
								>
									<span
										dangerouslySetInnerHTML={{ __html: get(barIcon, `icon.svg`, ``) }}
									/>
									{slug === `cart` && (
										<CartIcon />
									)}
								</DynamicLink>
							)
						})}
						<div>
							<button
								css={styles.navButton}
								onClick={toggleNavClick}
								dangerouslySetInnerHTML={{ __html: isNavOpen ? activeNavIcon : defaultNavIcon }}
							/>
						</div>
						<nav css={[styles.nav, isNavOpen && styles.openNav]}>
							<div css={styles.navMenu}>
								<NavMenu links={links} />
							</div>
							{!!headerData.bottomCopy && (
								<div css={styles.bottomCopy}>
									<SanityBlock body={headerData.bottomCopy.copyBlockContent} />
								</div>
							)}
							<SocialButtons />
						</nav>
					</div>
				</LayoutWrapper>
			</header>
		</ClickOutside>
	</>
}


const iconColor = get(settings, `topBarIconColor.color.hex`, `#fff`)
const hoverColor = get(settings, `topBarIconHoverColor.color.hex`, iconColor)
const styles = {
	navMenu: css`
		margin: 30px 0;
	`,
	bottomCopy: css`
		${renderStyles(settings.navBottomStyles || [])}
	`,
	navButton: css`
		padding: 0;
		width: auto;
		background: transparent;
		border: 0;
		min-width: 0;
		fill: ${get(navIcon, `color.color.hex`, `#fff`)};
		width: 35px;
		height: 35px;
		:hover{
			background: transparent;
			fill: ${get(navIcon, `hoverColor.color.hex`, `#fff`)};
		}
		:active{
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	`,
	header: css`
		background-color: ${get(settings, `backgroundColor.color.hex`, `#000`)};
		height: 50px;
		padding: 8px 0;
		color: #fff;
	`,
	row: css`
		display: flex;
		flex-wrap: wrap;
		position: relative;
	`,
	nav: css`
		display: none;
		flex-basis: 100%;
		background-color: ${get(settings, `navColor.color.hex`, `#000`)};
		position: absolute;
		z-index: 3;
		top: 42px;
		right: 0;
		width: 100%;
		text-align: right;
		padding: 20px;
		a{
			text-decoration: none;
			font-weight: bold;
			${renderStyles(navLinkStyles)}
		}
		@media(min-width:900px){
			max-width: 300px;
		}
	`,
	logo: css`
		position: relative;
		white-space: nowrap;
		height: 30px;
	`,
	barIcon: css`
		white-space: nowrap;
		margin-left: auto;
		position: relative;
		margin-right: 16px;
		width: 26px;
		height: 26px;
		top: 2px;
		fill: ${iconColor};
		:hover{
			fill: ${hoverColor};
		}
		path{
			fill: ${iconColor};
			:hover{
				fill: ${hoverColor};
			}
		}
	`,
	openNav: css`
		display: block;
	`,
}
