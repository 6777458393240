import React, { useEffect, useRef, useState, useContext } from 'react'
import imageUrlBuilder from '@sanity/image-url'
import { SanityClientContext, sizes } from './index'

export default function SanityBackgroundImage({
	children,
	asset,
	placeholder = true,
	quality = 90,
}){
	const client = useContext(SanityClientContext)
	const builder = imageUrlBuilder(client)
	const [urlWidth, setUrlWidth] = useState(0)
	const [url, setUrl] = useState(``)
	const [dimensions, setDimensions] = useState({})
	const [entered] = useState(true)
	const el = useRef()

	useEffect(() => {
		if(typeof window === `undefined` || !el.current) return
		function findDimensions() {
			const dimensions = el.current.getBoundingClientRect()
			setDimensions(dimensions)
		}
		findDimensions()
		window.addEventListener(`resize`, findDimensions)
		return () => {
			window.removeEventListener(`resize`, findDimensions)
		}
	}, [el.current, setDimensions])

	useEffect(() => {
		let useWidth = 0
		for(let i = sizes.length; i--;){
			const size = sizes[i]
			if(size > dimensions.width){
				useWidth = size
			}
			else{
				break
			}
		}
		if(useWidth > urlWidth){
			setUrlWidth(useWidth)
			const url = builder.image(asset).width(useWidth).quality(quality).url()
			setUrl(url)
		}
	}, [dimensions, urlWidth])

	useEffect(() => {
		setUrlWidth(0)
	}, [asset])

	const style = {
		backgroundSize: `cover`,
		backgroundRepeat: `no-repeat`,
		backgroundPosition: `center center`,
		// ...props.style,
	}
	if(url && entered){
		style.backgroundImage = `url("${url}")`
	}
	else if(placeholder && asset && asset.metadata) {
		style.backgroundImage = `url("${typeof placeholder === `string` ? placeholder : asset.metadata.lqip}")`
	}

	return (
		<div
			// className={className}
			// css={props.css}
			style={style}
			ref={el}
		>
			{children}
		</div>
	)
}