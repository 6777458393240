import React from 'react'
import { css } from '@emotion/core'
import DynamicLink from '../sanity-dynamic-link'

export const cmsId = `buttonLink`

export default function ButtonLink(props) {
	let { dynamicLink, ...spread } = props.module
	return (
		<div css={styles.wrapper}>
			<DynamicLink className='button' link={dynamicLink} {...spread} />
		</div>
	)
}

const styles = {
	wrapper: css`
		text-align: center;
		margin: 30px 0;
	`,
}