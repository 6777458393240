import React from 'react'
import get from 'lodash/get'
import { css } from '@emotion/core'
import data from '@data/sanity/footer.json'
import siteSettings from '@data/sanity/siteSettings'
import Link from '@app/link'
import SanityBlock from '@app/react-sanity-block-content'
import fonts from '@data/sanity/fonts.json'
import DynamicLink from '@app/react-sanity-dynamic-link'
import Img from '@app/responsive-image-sanity'
import SocialButtons from './social-buttons'
import LayoutWrapper from '@app/layout-wrapper'
import Brands from './brands'
import settings from '@data/sanity/wcFooterSettings.json'

const logo = get(data, `logo.asset`)
const primaryLinksObj = get(data, `primaryLinks`, {})
const primaryHeaderImg = get(primaryLinksObj, `image.asset`)
const primaryHeaderLink = get(primaryLinksObj, `link`)
const primaryLinks = get(primaryLinksObj, `links`, [])
const secondaryLinks = get(data, `secondaryLinks`, [])

export default function Footer(){
	return(
		<footer css={styles.footer}>
			<div css={styles.social}>
				<LayoutWrapper>
					<div css={styles.row}>
						<h1 css={styles.socialHeader}>
							{data.socialMediaHeader && (
								<DynamicLink link={data.socialMediaHeader.link}>
									{data.socialMediaHeader.text}
								</DynamicLink>
							)}
						</h1>
						<div css={styles.socialButtons}>
							<SocialButtons />
						</div>
					</div>
				</LayoutWrapper>
			</div>

			<div css={styles.navs}>
				<LayoutWrapper>
					<div css={[styles.row, styles.footerLinks]}>
						<div css={styles.logo}>
							{!!logo && (
								<Link to='/'>
									<Img asset={logo} alt={siteSettings.title} />
								</Link>
							)}
						</div>
						<div css={styles.primary}>
							<div css={styles.primaryHeader}>
								{!!primaryHeaderImg && !!primaryHeaderLink && (
									<DynamicLink link={primaryHeaderLink}>
										<Img
											asset={primaryHeaderImg}
											alt={get(primaryHeaderLink, `internalLink.title`, `Promo link`)}
										/>
									</DynamicLink>
								)}
							</div>
							<div css={[styles.linkList, styles.primaryLinks]}>
								{primaryLinks.map((link, key) => {
									return (
										<div key={key}>
											<DynamicLink link={link.link}>
												{link.title}
											</DynamicLink>
										</div>
									)
								})}
							</div>
						</div>
						<div css={[styles.linkList, styles.secondaryLinks]}>
							{secondaryLinks.map((link, key) => {
								return (
									<div key={key}>
										<DynamicLink link={link.link}>
											{link.title}
										</DynamicLink>
									</div>
								)
							})}
						</div>
						<div css={styles.contact}>
							<SanityBlock body={get(data, `contactInformation.copyBlockContent`)} />
						</div>
					</div>
					<div css={[styles.row, styles.bottom]}>
						<div css={styles.brands}>
							<Brands brands={get(data, `otherBrands`, [])} />
						</div>
						<div css={styles.copyright}>
							{(data.copyright || ``).replace(`{{year}}`, (new Date()).getFullYear())}
						</div>
					</div>
				</LayoutWrapper>
			</div>
		</footer>
	)
}

const breakpoint = 900

const styles = {
	primaryHeader: css`
		@media(min-width:${breakpoint}px){
			height: 30px;
		}
	`,
	bottom: css`
		opacity: .5;
	`,
	secondaryLinks: css`
		a{
			color: ${get(settings, `linkColor.color.hex`, `#fff`)};
		}
		/* @media(min-width:${breakpoint}px){
			margin-top: 60px;
		} */
	`,
	socialButtons: css`
		@media(min-width: ${breakpoint}px){
			margin-top: 35px;
			ul{
				text-align: right;
			}
		}
	`,
	copyright: css`
		font-size: .7em;
		margin-top: 30px;
		text-align: center;
		@media(min-width: ${breakpoint}px){
			order: 1;
			text-align: left;
			white-space: nowrap;
			margin-right: 60px;
			/* margin-top: 70px; */
		}
	`,
	brands: css`
		text-align: center;
		@media(min-width: ${breakpoint}px){
			order: 2;
			text-align: right;
		}
	`,
	contact: css`
		h1, h2, h3, p{
			margin: 0 0 5px 0;
		}
		a{
			text-decoration: none;
		}
		/* @media(min-width:${breakpoint}px){
			margin-top: 55px;
		} */
	`,
	linkList: css`
		a{
			text-decoration: none;
			font-weight: bold;
			${fonts.primary};
			text-transform: uppercase;
			font-size: 1.3em;
			padding: 5px 0;
			display: inline-block;
			:hover{
				opacity: .6;
			}
		}
	`,
	primaryLinks: css`
		margin-top: 30px;
	`,
	socialHeader: css`
		text-align: center;
		a{
			color: ${get(settings, `socialTextColor.color.hex`, `#fff`)};
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;
		}
		@media(min-width: ${breakpoint}px){
			text-align: left;
		}
	`,
	social: css`
		background: ${get(settings, `socialBgColor.color.hex`, `#000`)};
	`,
	footerLinks: css`
		margin: 0 -10px;
		> *{
			padding: 15px 0;
			text-align: center;
			@media(min-width: ${breakpoint}px){
				text-align: right;
				padding: 10px;
				:nth-of-type(1){
					width: 25%;
					text-align: center;
				}
				:nth-of-type(2){
					width: 25%;
				}
				:nth-of-type(3){
					width: 25%;
				}
				:nth-of-type(4){
					width: 25%;
				}
			}
		}
	`,
	navs: css`
		background: ${get(settings, `footerBgColor.color.hex`, `#000`)};
		color: ${get(settings, `fontColor.color.hex`, `#fff`)};
		padding: 30px 0;
		p{
			color: ${get(settings, `fontColor.color.hex`, `#fff`)};
		}
		a{
			color: ${get(settings, `linkColor.color.hex`, `#fff`)};
		}
	`,
	row: css`
		@media(min-width: ${breakpoint}px){
			display: flex;
			flex-direction: row;
			> *{
				flex-grow: 1;
			}
		}
	`,
}