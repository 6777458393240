import { useEffect } from 'react'
import { useUlysses } from '@smarterlabs/ulysses'


export default function WCShopifyAttributesPlugin() {
	const ulysses = useUlysses()

	useEffect(() => {
		async function onCheckout({ client, checkout }) {
			console.log(`WCShopifyAttributesPlugin checkout`, checkout)
			const attrs = {}
			// Hack to get cart contents
			let lineItems = localStorage[`ulysses-v1`]
			if(lineItems){
				lineItems = JSON.parse(lineItems).lineItems
			}
			if(!lineItems){
				lineItems = []
			}
			for(let key = 0; key < lineItems.length; key++){
				const item = lineItems[key]
				const string = item.wcStringConfig
				if (string) {
					attrs[`bowType${key}`] = string.bowType
					attrs[`color1Name${key}`] = string.strand1.name
					attrs[`color2Name${key}`] = string.strand2.name
					attrs[`colorServingName${key}`] = string.serving.name
					attrs[`material${key}`] = string.string.name
					attrs[`notesToBuilder${key}`] = string.notesToBuilder
					attrs[`price${key}`] = string.variant.price
					attrs[`stringCustomizationLevel${key}`] = string.stringCustomizationLevel
					attrs[`stringLength${key}`] = string.stringLength
					attrs[`stringSet${key}`] = string.stringSet
					if (string.bowType === `compound`) {
						attrs[`bowBrandSelected${key}`] = string.bowBrand
						attrs[`bowModelSelected${key}`] = string.bowModel
					}
				}
			}
			const customAttributes = []
			Object.keys(attrs).forEach(key => {
				const value = attrs[key]
				if(value === null || value === undefined) return
				customAttributes.push({
					key,
					value,
				})
			})
			await client.checkout.updateAttributes(checkout.id, { customAttributes })
		}

		ulysses.on(`checkout:shopify`, onCheckout)
	}, [])
	return null
}