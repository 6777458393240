import React from 'react'
import { css } from '@emotion/core'
import get from 'lodash/get'
import data from '@data/sanity/socialLink.json'
import Img from '@app/responsive-image-sanity'
import settings from '@data/sanity/wcFooterSettings.json'

export default function SocialButtons(){
	return(
		<ul css={styles.list}>
			{data.map((link) => {
				return (
					<li key={link._id} css={styles.item}>
						<a href={link.link} css={styles.link} target='_blank' rel='noopener noreferrer'>
							<Img asset={get(link, `icon.asset`)} maxWidth={40} alt='Social media icon' />
						</a>
					</li>
				)
			})}
		</ul>
	)
}

const styles = {
	list: css`
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;
	`,
	item: css`
		display: inline-block;
		padding: 5px;
	`,
	link: css`
		color: ${get(settings, `socialIconColor.color.hex`, `#000`)};
		:hover{
			opacity: .6;
		}
	`,
}