import React from 'react'
import get from 'lodash/get'
import { css } from '@emotion/core'
import BlockContent from '@sanity/block-content-to-react'
import SanityImg from '@app/responsive-image-sanity'
import DynamicLink from '@app/react-sanity-dynamic-link'
import pluginBlocks from '@rdk/config/react-block-renderers'

const serializers = {
	types: {
		block(props) {
			switch (props.node.style) {
				case `h1`:
					return <h1>{props.children}</h1>

				case `h2`:
					return <h2>{props.children}</h2>

				case `h3`:
					return <h3>{props.children}</h3>

				case `h4`:
					return <h4>{props.children}</h4>

				case `blockquote`:
					return <blockquote>{props.children}</blockquote>

				default:
					return <p>{props.children}</p>
			}
		},
		...pluginBlocks.default,
		buttonLink({ node }){
			return <ButtonLink {...node} />
		},
		seoImage({ node }){
			return <SanityImg asset={node.asset} alt={node.alt} maxWidth={node.maxWidth} />
		},
	},
	marks: {
		center({ children }){
			return <div style={{textAlign: `center`}}>{children}</div>
		},
		right({ children }){
			return <div style={{textAlign: `right`}}>{children}</div>
		},
		link(props){
			return <DynamicLink link={props.mark.dynamicLink} text={props.children} />
		},
		phoneLink(props){
			const number = get(props, `mark.phone`)
			if(!number) return props.children
			return <a href={`tel:${number}`}>{props.children}</a>
		},
		emailLink(props){
			const email = get(props, `mark.email`)
			if (!email) return props.children
			return <a href={`mailto:${email}`}>{props.children}</a>
		},
		color(props){
			const color = get(props, `mark.color.hex`)
			return <span style={{color}}>{props.children}</span>
		},
	},
}

export default function SanityBlock({ body, ...props }){
	return (
		<BlockContent blocks={body} serializers={serializers} {...props} />
	)
}

function ButtonLink(props) {
	let { dynamicLink, ...spread } = props
	return (
		<div css={styles.wrapper}>
			<DynamicLink className='button' link={dynamicLink} {...spread} />
		</div>
	)
}

const styles = {
	wrapper: css`
		text-align: center;
		margin: 30px 0;
	`,
}