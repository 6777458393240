import React from 'react'
import { Helmet } from 'react-helmet'
import data from '@data/sanity/googleFontsSettings.json'
import getUrls from 'get-urls'
import get from 'lodash/get'

const html = get(data, `settings.embedHtml`, ``)
const urls = getUrls(html)
const url = Array.from(urls)[0]

export default children => {
	return (
		<>
			<Helmet>
				{!!url && (
					<link href={url} rel="stylesheet"></link>
				)}
			</Helmet>
			{children}
		</>
	)
}