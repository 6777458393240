import React from 'react'
import { css } from '@emotion/core'
import LayoutWrapper from '@app/layout-wrapper'

export default function Alert(props) {
	return (
		<LayoutWrapper>
			<div css={[styles.alert, styles[props.type || `info`]]}>
				{props.children}
			</div>
		</LayoutWrapper>
	)
}

const styles = {
	alert: css`
		border-width: 1px;
		border: 1px solid transparent;
		padding: 1em;
		border-radius: .25em;
		margin: 1em 0;
	`,
	success: css`
		color: #155724;
		background-color: #d4edda;
		border-color: #c3e6cb;
	`,
	error: css`
		color: #721c24;
		background-color: #f8d7da;
		border-color: #f5c6cb;
	`,
	warning: css`
		color: #856404;
		background-color: #fff3cd;
		border-color: #ffeeba;
	`,
	info: css`
		color: #0c5460;
		background-color: #d1ecf1;
		border-color: #bee5eb;
	`,
}