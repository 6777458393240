const imports = [
	require(`@rdk/content-module-video/react-content-modules.js`),
	require(`@rdk/seo-plugin/react-content-modules.js`),
	require(`@rdk/message-bar-plugin/react-content-modules.js`),
	require(`@rdk/bread-crumbs-plugin/react-content-modules.js`),
	require(`@rdk/html-module/react-content-modules.js`),
	require(`@rdk/hubspot-plugin/react-content-modules.js`),
	require(`@rdk/hero-plugin/react-content-modules.js`),
	require(`@rdk/split-hero-plugin/react-content-modules.js`),
	require(`@rdk/wc-third-hero-plugin/react-content-modules.js`),
	require(`@rdk/image-header-plugin/react-content-modules.js`),
]
const modules = {}
imports.forEach(obj => {
	if(obj.default) obj = obj.default
	for(let i in obj){
		modules[i] = obj[i]
	}
})
module.exports = modules