import React from 'react'
import useHubspotForm from '@aaronhayes/react-use-hubspot-form'
import Loading from '@app/loading'
import Alert from '@app/alert'
import LayoutWrapper from '@app/layout-wrapper'

export const cmsId = `hubspotForm`

export default function HubspotForm(props) {
	const id = `hubspotForm-${props._key}`

	const options = {
		portalId: props.portalId,
		formId: props.formId,
		target: `#${id}`,
	}
	const form = useHubspotForm(options)

	return(
		<LayoutWrapper>
			{form.error && (
				<Alert type='error'>{form.error}</Alert>
			)}
			{!form.loaded && (
				<Loading />
			)}
			<div id={id} />
		</LayoutWrapper>
	)
}