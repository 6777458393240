import React from 'react'
import { css } from '@emotion/core'
import get from 'lodash/get'
import headerData from '@data/sanity/wcHeader.json'
import settings from '@data/sanity/wcHeaderSettings.json'
import DynamicLink from '@app/react-sanity-dynamic-link'

const data = headerData.bottomIconLinks || []

export default function SocialButtons(){
	return(
		<ul css={styles.list}>
			{data.map((link) => {
				return (
					<li key={link._id} css={styles.item}>
						<DynamicLink
							link={link.link}
							css={styles.link}
						>
							<span dangerouslySetInnerHTML={{__html: get(link, `icon.svg`, ``)}} />
						</DynamicLink>
					</li>
				)
			})}
		</ul>
	)
}

const color = get(settings, `bottomIconColor.color.hex`, `#fff`)
const hoverColor = get(settings, `bottomIconHoverColor.color.hex`, color)
const iconSize = 40

const styles = {
	list: css`
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;
	`,
	item: css`
		display: inline-block;
		padding: 5px;
	`,
	link: css`
		display: inline-block;
		width: ${iconSize}px;
		height: ${iconSize}px;
		fill: ${color} !important;
		color: ${color} !important;
		:hover{
			fill: ${hoverColor} !important;
			color: ${hoverColor} !important;
		}
		path{
			fill: ${color} !important;
			color: ${color} !important;
			:hover{
				fill: ${hoverColor} !important;
				color: ${hoverColor} !important;
			}
		}
		svg{
			width: 100%;
			height: 100%;
		}
	`,
}