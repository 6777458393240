import React from 'react'
import { css } from '@emotion/core'
import settings from '@data/sanity/breadCrumbsSettings.json'
import { renderStyles } from '@app/sanity-style-fields/css'
import DynamicLink from '@app/react-sanity-dynamic-link'

const prependLinks = settings.prependLinks || []

export default function BreadCrumbs(props) {
	const module = props.module || {}
	const links = [
		...prependLinks,
		...module.links || [],
	]

	return(
		<ul css={styles.list}>
			{links.map((link) => {
				return (
					<>
						<li css={styles.item}>
							<DynamicLink link={link.link}>
								{link.title}
							</DynamicLink>
						</li>
						<li css={[styles.item, styles.delimiter]}>/</li>
					</>
				)
			})}
			<li css={styles.item}>{props.pageTitle || `Current Page`}</li>
		</ul>
	)
}

const spacing = 5

const styles = {
	list: css`
		list-style-type: none;
		margin: 20px -${spacing}px;
		${renderStyles(settings.bodyStyles)}
		padding: 0;
		a{
			${renderStyles(settings.linkStyles)}
		},
	`,
	delimiter: css`
		${renderStyles(settings.delimiterStyles)}
	`,
	current: css`
		${renderStyles(settings.currentStyles)}
	`,
	item: css`
		display: inline-block;
		margin: 0 ${spacing}px;
	`,
}