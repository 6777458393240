import React from 'react'
import { css } from '@emotion/core'
import PhoneIcon from '@meronex/icons/fa/FaPhone'
import EmailIcon from '@meronex/icons/fa/FaEnvelope'
import SanityBlock from '@app/react-sanity-block-content'

export const cmsId = `contactInfo`

export default function ContactBlock(props) {
	props = props.module
	return(
		<section css={styles.section}>
			{!!props.copyBlockContent && (
				<SanityBlock body={props.copyBlockContent} />
			)}
			<div css={styles.info}>
				<div css={styles.column}>
					{props.phone && (
						<div css={styles.lockUp}>
							<div css={styles.icon}>
								<PhoneIcon />
							</div>
							<div>
								<h4>Call Us:</h4>
								<div><a href={`tel:${props.phone}`}>{props.phone}</a></div>
							</div>
						</div>
					)}
					{props.email && (
						<div css={styles.lockUp}>
							<div css={styles.icon}>
								<EmailIcon />
							</div>
							<div>
								<h4>Email Us:</h4>
								<div><a href={`mailto:${props.email}`}>{props.email}</a></div>
							</div>
						</div>
					)}
				</div>
				<div css={styles.column}>
					{props.address && (
						<div>
							<h4>Mail Us:</h4>
							<div>{props.address.split(`\n`).map((line, index) => {
								return (
									<div key={index}>{line}</div>
								)
							})}</div>
						</div>
					)}
					{props.fax && (
						<div>
							<h4>Fax Us:</h4>
							<div><a href={`fax:${props.fax}`}>props.fax</a></div>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}


const styles = {
	section: css`
		max-width: 400px;
		margin: 30px auto;
	`,
	info: css`
		display: flex;
		margin: 0 -10px;
		h4{
			margin: 5px 0;
		}
	`,
	icon: css`
		width: 30px;
		position: relative;
		font-size: 1.3em;
		> *{
			position: absolute;
			top: 50%;
			transform: translate(0%, -50%);
		}
	`,
	lockUp: css`
		display: flex;
		margin-bottom: 30px;
	`,
	column: css`
		padding: 0 10px;
	`,
	title: css`
		text-align: center;
	`,
	subtitle: css`
		text-align: center;
	`,
}