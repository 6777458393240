import React from 'react'
import { Helmet } from 'react-helmet'
import siteConfig from '@data/sanity/siteSettings'

function createPageTitle(pageTitle){
	return pageTitle ? `${pageTitle} | ${siteConfig.title}` : siteConfig.title
}

export default function SEO(props) {
	const { module } = props
	const og = module.og || {}
	const twitter = module.twitter || {}

	return(
		<Helmet>
			{!!module.pageTitle && (
				<title>{createPageTitle(module.pageTitle)}</title>
			)}
			{!!module.description && (
				<meta name='description' content={module.description} />
			)}

			{!!og.description && (
				<meta name='og:title' content={og.title} />
			)}
			{!!og.title && (
				<meta name='og:description' content={og.description} />
			)}
			{!!og.image && (
				<meta name='og:image' content={og.image.url} />
			)}
			{!!og.siteName && (
				<meta name='og:site_name' content={og.siteName} />
			)}

			{!!twitter.title && (
				<meta name='twitter:title' content={twitter.title} />
			)}
			{!!twitter.description && (
				<meta name='twitter:description' content={twitter.description} />
			)}
			{!!twitter.image && (
				<meta name='twitter:image' content={twitter.image.url} />
			)}


			{module.robotsNoFollow && (
				<meta name='robots' content='noindex, nofollow' />
			)}
		</Helmet>
	)
}