import React from 'react'
import { useFormik } from 'formik'
import Layout from '@app/layout-wrapper'
import fetch from 'isomorphic-fetch'
import get from 'lodash/get'
import Alert from '@app/alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import validationSchema from './validation'

export default function ContactForm() {
	const { executeRecaptcha } = useGoogleReCaptcha()

	const formik = useFormik({
		initialValues: {
			name: ``,
			email: ``,
			message: ``,
		},
		validationSchema,
		onSubmit: async (values, { setStatus }) => {
			const token = await executeRecaptcha(`contact_form`)
			const body = JSON.stringify({
				recaptchaToken: token,
				...values,
			})
			try {
				const res = await fetch(`/.netlify/functions/contact-form-handler`, {
					method: `post`,
					body,
				})
				const data = await res.json()
				if(data.success){
					setStatus({ success: true })
				}
				else{
					if(data.errors){
						setStatus({ errors: data.errors })
					}
					else {
						setStatus({ error: `Server error. Please try again later.` })
					}
				}
			}
			catch(err){
				console.log(`Error fetching API`)
				setStatus({ errors: [`Server error. Please try again later.`] })
				console.error(err)
			}
		},
	})

	const errors = get(formik, `status.errors`, [])
	const success = get(formik, `status.success`, false)

	function isValid(label){
		return !formik.errors[label]  || !formik.touched[label]
	}

	return (
		<>
			{errors.map((error, index) => {
				return (
					<Alert type='error' key={index}>{error}</Alert>
				)
			})}
			{success && (
				<Alert type='success'>Thank you for contacting us! We will be in touch as soon as possible!</Alert>
			)}
			{!success && (
				<Layout>
					<form className='normform' onSubmit={formik.handleSubmit}>
						<div>
							<label>
								Name:
								{!isValid(`name`) ? (
									<div className='error' style={{ marginBottom: 10 }}>{formik.errors.name}</div>
								) : null}
								<input
									name='name'
									type='text'
									onChange={formik.handleChange}
									value={formik.values.name}
									className={!isValid(`name`) ? `invalid` : null}
								/>
							</label>
						</div>
						<div>
							<label>
								Email:
								{!isValid(`email`) ? (
									<div className='error'>{formik.errors.email}</div>
								) : null}
								<input
									name='email'
									type='text'
									onChange={formik.handleChange}
									value={formik.values.email}
									className={!isValid(`email`) ? `invalid` : null}
								/>
							</label>
						</div>
						<div>
							<label>
								Message:
								{!isValid(`message`) ? (
									<div className='error'>{formik.errors.message}</div>
								) : null}
								<textarea
									name='message'
									onChange={formik.handleChange}
									value={formik.values.message}
									className={!isValid(`message`) ? `invalid` : null}
								/>
							</label>
						</div>
						<button type='submit' disabled={formik.isSubmitting}>
							{formik.isSubmitting ? `Submitting...` : `Submit`}
						</button>
					</form>
				</Layout>
			)}
		</>
	)
}