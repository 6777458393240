import React from 'react'
import Video from './react/video'
import LayoutWrapper from '@app/layout-wrapper'

function VideoModule(props){
	return(
		<LayoutWrapper>
			<Video {...props.module} />
		</LayoutWrapper>
	)
}


export default {
	video: VideoModule,
}