import React from 'react'
import get from 'lodash/get'
import { css } from '@emotion/core'
import settings from '@data/sanity/thirdHeroSettings.json'
import BlockContent from '@app/react-sanity-block-content'
import BgImg from '@app/responsive-image-sanity/background'
import renderCmsCss from '@app/sanity-style-fields/css'

export default function ThirdHero(props){
	const module = props.module || {}
	return (
		<section className='thirdHeroModule' css={styles.container}>
			<div css={styles.image}>
				<BgImg asset={get(module, `backgroundImage.asset`)} />
			</div>
			<div css={styles.copy}>
				<BlockContent body={module.copyBlockContent} />
			</div>
		</section>
	)
}

const smallBp = 900
const largeBp = 1200
const third = 100 / 3

const styles = {
	container: css`
		position: relative;
		${renderCmsCss(settings)}
	`,
	copy: css`
		position: relative;
		text-align: center;
		padding: 20px;
		@media(min-width: ${smallBp}px){
			background-color: ${get(settings, `bgColor.color.hex`, `#000`)};
			width: 50%;
		}
		@media(min-width: ${largeBp}px){
			width: ${third}%;
		}
	`,
	image: css`
		> *{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-size: cover;
			background-repeat: no-repeat;
			@media(min-width: ${smallBp}px){
				left: 50%;
			}
			@media(min-width: ${largeBp}px){
				left: ${third}%;
			}
		}
	`,
}