import React, { useState } from 'react'
import { css } from '@emotion/core'
import get from 'lodash/get'
import settings from '@data/sanity/videoModuleSettings.json'
import Img from '@app/responsive-image-sanity'
import VideoModal from '@app/react-video-modal'

const playIcon = get(settings, `playIcon.asset`)

export default function Video(props) {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<div css={styles.container}>
			<a href='#' css={styles.link} onClick={e => {
				e.preventDefault()
				setIsOpen(true)
			}}>
				<Img asset={get(props, `placeholderImage.asset`)} alt={props.title} css={styles.placeholderImage} />
				{!!playIcon && (
					<div css={styles.playIcon}>
						<Img asset={playIcon} alt='Play icon' maxWidth={100} />
					</div>
				)}
			</a>
			<VideoModal isOpen={isOpen} link={props.mediaLink} onClose={() => setIsOpen(false)} />
		</div>
	)
}

const styles = {
	link: css`
		display: block;
		text-align: center;
	`,
	container: css`
		position: relative;
	`,
	placeholderImage: css`
		margin: 0 auto;
	`,
	playIcon: css`
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	`,
}