import React from 'react'
import { css } from '@emotion/core'
import SanityBlock from '@app/react-sanity-block-content'
import settings from '@data/sanity/messageBarSettings.json'
import get from 'lodash/get'
import renderCmsCss from '@app/sanity-style-fields/css'

export default function MessageBar(props){
	const { copyBlockContent } = props.module
	return(
		<div css={styles.bar}>
			<SanityBlock body={copyBlockContent} />
		</div>
	)
}

const styles = {
	bar: css`
		background-color: ${get(settings, `bgColor.color.hex`, `#000`)};
		padding: 10px 10px;
		text-align: center;
		font-size: .85em;
		p{
			margin: 0;
		}
		${renderCmsCss(settings)}
	`,
}