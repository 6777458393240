import React from 'react'
import { css } from '@emotion/core'
// import get from 'lodash/get'
import Module from '../sanity-module'
import Wrapper from '@app/layout-wrapper'

export const cmsId = `columns`

export default function ModuleColumns(props){
	props = props.module
	const spacing = props.spacing || 0
	let columns = (
		<div css={styles.row} style={{margin: `0 -${spacing}px`}}>
			{props.modules.map((module, index) => {
				return (
					<div key={index} style={{padding: spacing}}>
						<Module module={module} />
					</div>
				)
			})}
		</div>
	)
	if(props.constrain){
		columns = <Wrapper>{columns}</Wrapper>
	}
	return columns
}

const styles = {
	row: css`
		@media(min-width: 960px){
			display: flex;
			flex-direction: row;
			>*{
				width: 100%;
			}
		}
	`,
}