import React, { useContext, useEffect, useState } from 'react'
import get from 'lodash/get'
import IdealImg from 'react-ideal-image'
import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

export const SanityClientContext = React.createContext()

export const SanityClient = ({ children, ...clientConfig }) => (
	<SanityClientContext.Provider value={sanityClient(clientConfig)}>
		{children}
	</SanityClientContext.Provider>
)

export const sizes = [640, 768, 1024, 1366, 1600, 1920, 2500]

export default function ResponsiveSanityImg({
	asset,
	maxWidth,
	width,
	height,
	placeholder = true,
	quality = 90,

	...props
}) {
	const client = useContext(SanityClientContext)
	const builder = imageUrlBuilder(client)
	if (!asset) return null
	const meta = get(asset, `metadata`)
	const dimensions = meta.dimensions || {}
	let fill = height ? true : false
	if(!width){
		width = dimensions.width
	}
	if (!height){
		height = dimensions.height
	}
	const lqip = get(meta, `lqip`, ``)
	const [srcSet, setSrcSet] = useState(getSrcSet(asset))
	const [renderImg, setRenderImg] = useState(true)

	if(!maxWidth) maxWidth = width

	if (placeholder === true) {
		placeholder = { lqip }
	}

	function getSrcSet(asset) {
		const srcSet = []
		for (let i = 0; i < sizes.length; i++) {
			const size = sizes[i]
			let chain = builder
				.image(asset)
				.ignoreImageParams()
				.quality(quality)
			if(fill){
				chain = chain
					.fit(`fillmax`)
					.bg(`fff`)
			}
			if (size >= width) {
				srcSet.push({
					width,
					src: chain
						.width(width)
						.height(height)
						.url(),
				})
				break
			}
			srcSet.push({
				width: size,
				src: chain
					.width(size)
					.height(Math.floor((size * height) / width))
					.url(),
			})
		}
		return srcSet
	}

	useEffect(() => {
		const newSrcSet = getSrcSet(asset)
		setRenderImg(false)
		setSrcSet(newSrcSet)
		setTimeout(() => setRenderImg(true), 1)
	}, [asset])

	return (
		<div style={{ maxWidth, display: `inline-block` }}>
			{/* <img src={srcSet[0].src} /> */}

			{renderImg && (
				<IdealImg
					{...props}
					src={srcSet[0].src}
					width={width}
					height={height}
					placeholder={placeholder || { color: `transparent` }}
					srcSet={srcSet}
				/>
			)}
			{!renderImg && (
				<div
					style={{
						position: `relative`,
						width: `100%`,
						height: `auto`,
						paddingBottom: `${(height / width) * 100}%`,
						color: `transparent`,
					}}
				>
					{props.alt || `image`}
				</div>
			)}
			{/* <div
				style={{
					position: `relative`,
					width: `100%`,
					height: `auto`,
					paddingBottom: `${(origWidth/origHeight) * 100}%`,
				}}
			></div> */}
		</div>
	)
}