import React from 'react'
import { css } from '@emotion/core'
import DynamicLink from '@app/react-sanity-dynamic-link'

function BrandBlock({image, url, isActive, title}){
	const img = (
		<img
			src={image.url}
			alt={title}
			css={styles.brandImg}
		/>
	)
	// const img = <SanityImg asset={image} alt={image.alt} maxWidth={image.maxWidth} />
	return(
		<div css={[styles.brand, isActive && styles.activeBrand]}>
			{url ? (
				<a href={url} target={isActive ? null : `_blank`} rel="noopener noreferrer">{img}</a>
			) : img}

		</div>
	)
}

export default function BrandsBanner(props) {
	const brands = props.brands || []
	let rightText = (
		<span css={styles.rightSideText}>{props.rightText}</span>
	)
	
	return(
		<div css={styles.container}>
			<div css={styles.brands}>
				{props.activeBrand && (
					<BrandBlock
						image={props.activeBrand.image.asset}
						url={props.activeBrand.url}
						title={props.activeBrand.title}
						isActive={true}
					/>
				)}
				{brands.map((brand, index) => (
					<BrandBlock
						key={index}
						image={brand.image.asset}
						title={brand.title}
						url={brand.url}
					/>
				))}
			</div>
			<div css={styles.rightSide}>
				{props.rightLink ? (
					<DynamicLink link={props.rightLink}>{rightText}</DynamicLink>
				) : rightText}
			</div>
		</div>
	)
}

const height = 50
const padding = 10

const styles = {
	container: css`
		background-color: #192323;
		position: relative;
		text-align: center;
		border-bottom: 1px solid #fff;
		@media(min-width: 768px) {
			text-align: left;
		}
	`,
	brand: css`
		display: inline-block;
		opacity: .7;
		filter: grayscale(1);
		transition: opacity .3s ease-in-out, filter .3s ease-in-out;
		position: relative;
		padding: 5px;
		height: ${height - 20}px;
		:hover {
			opacity: 1;
			filter: grayscale(0);
		}
		@media(min-width: 768px) {
			height: ${height}px;
			padding: ${padding}px;
		}
	`,
	activeBrand: css`
		opacity: 1;
		filter: grayscale(0);
		background: #fff;
		display: none;
		@media(min-width: 768px){
			display: inline-block;
		}
	`,
	rightSide: css`
		padding: 5px ${padding}px;
		position: relative;
		@media(min-width: 768px){
			position: absolute;
			top: 0;
			right: 0;
			height: ${height}px;
			width: 40%;
		}
	`,
	rightSideText: css`
		color: #fff;
		font-size: .9em;
		@media(min-width: 768px){
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 0;
			text-align: right;
			transform: translateY(-50%);
			padding-right: 10px;
		}
	`,
	brandImg: css`
		height: 100%;
		height: ${height - (padding * 2)}px;
	`,
}