import React, { useState } from 'react'
import Context from './context'

export default function ContextProvider({ children }) {
	let initialStoreState = {
		step: `string`,
	}
	const [store, setStore] = useState(initialStoreState)

	function mergeStore(newStore){
		setStore({
			...store,
			...newStore,
		})
	}

	return (
		<Context.Provider value={[store, mergeStore]}>
			{children}
		</Context.Provider>
	)
}