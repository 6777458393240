import React from 'react'
import { Global, css } from '@emotion/core'
import { Helmet } from 'react-helmet'
import { ShopifyProvider } from '@app/react-shopify'
import { SanityClient } from '@app/responsive-image-sanity'
import SearchProvider from '@app/use-search/provider'
import color from '@app/dynamic-color'
import data from '@data/sanity/siteTemplate.json'
import layoutAssets from '@data/sanity/layoutAssets.json'
import get from 'lodash/get'
import { UlyssesProvider } from '@smarterlabs/ulysses'
import UlyssesPluginShopify from '@smarterlabs/ulysses-plugin-shopify'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import recaptchaSettings from '@data/sanity/googleRecaptchaSettings.json'
import globalStyles from '@data/sanity/globalStyles.json'
import shopifyPlugin from '@data/sanity/shopifyPlugin'
import imageBuilder from '@app/sanity-image-builder'
import pluginWrappers from '@rdk/config/react-app-wrappers'
import pluginHeaders from '@rdk/config/react-site-headers'
import pluginFooters from '@rdk/config/react-site-footers'
import Modules from '../components/sanity-module'
import formsCss from './_forms.css.js'
import renderCmsCss from '@app/sanity-style-fields/css'
import ulyssesPlugins from '@rdk/config/ulysses-plugins'

const preHeaderModules = get(data, `preHeaderModules`, [])
const headerModules = get(data, `headerModules`, [])
const footerModules = get(data, `footerModules`, [])
const postFooterModules = get(data, `postFooterModules`, [])
const icon = get(layoutAssets, `siteIcon.asset`)
const globalCSS = get(globalStyles, `css.code`)

function iconUrl(size){
	return imageBuilder
		.image(icon)
		.width(size)
		.url()
}

export default function Layout(props) {
	const { children } = props

	let layout = (<>
		{!!icon && (
			<Helmet>
				<link rel="icon" type="image/png" href={iconUrl(16)} sizes="16x16" />
				<link rel="icon" type="image/png" href={iconUrl(32)} sizes="32x32" />
				<link rel="icon" type="image/png" href={iconUrl(96)} sizes="96x96" />
			</Helmet>
		)}
		<Global styles={styles.global} />
		{!!globalCSS && (
			<Global styles={globalCSS} />
		)}
		<Global styles={formsCss} />
		<Modules modules={preHeaderModules} />
		{pluginHeaders.map((Header, index) => (
			<Header key={`header${index}`} />
		))}
		<Modules modules={headerModules} />
		{children}
		<Modules modules={footerModules} />
		{pluginFooters.map((Footer, index) => (
			<Footer key={`footer${index}`} />
		))}
		<Modules modules={postFooterModules} />
	</>)

	if (recaptchaSettings.enabled){
		const { siteKey } = recaptchaSettings.options || {}
		if (siteKey) {
			layout = (
				<GoogleReCaptchaProvider reCaptchaKey={siteKey}>
					{layout}
				</GoogleReCaptchaProvider>
			)
		}
	}

	layout = <SearchProvider>{layout}</SearchProvider>

	if (process.env.SANITY_DATASET){
		layout = (
			<SanityClient
				dataset={process.env.SANITY_DATASET}
				projectId={process.env.SANITY_PROJECT_ID}
			>
				{layout}
			</SanityClient>
		)
	}

	if (shopifyPlugin.enabled){
		const { storeName, accessToken } = shopifyPlugin.settings || {}
		layout = (
			<ShopifyProvider>
				<UlyssesProvider localStorageKey='ulysses-v1'>
					<UlyssesPluginShopify
						client={{
							storefrontAccessToken: accessToken,
							domain: `${storeName}.myshopify.com`,
						}}
					/>
					{ulyssesPlugins.map((Plugin, key) => (
						<Plugin key={key} />
					))}
					{layout}
				</UlyssesProvider>
			</ShopifyProvider>
		)
	}

	pluginWrappers.forEach((wrapper) => {
		layout = wrapper.default(layout)
	})

	return layout
}

const styles = {
	global: css`
		html {
			height: 100%;
			box-sizing: border-box;
		}
		*, *:before, *:after {
			box-sizing: inherit;
		}
		body {
			position: relative;
			height: 100%;
			margin: 0;
			text-rendering: optimizeLegibility;
			font-size: 16px;
			overflow-x: hidden;
		}
		html, body {
			-webkit-tap-highlight-color: transparent;
			max-width: 100%;
		}
		img{
			max-width: 100%;
		}

		button, .button{
			padding: 5px 10px;
			background: ${color(`black`, `black`)};
			color: ${color(`white`, `white`)};
			border: 1px solid ${color(`black`, `black`)};
			text-transform: uppercase;
			font-weight: bold;
			outline: 0;
			text-decoration: none;
			min-width: 100px;
			display: inline-block;
			cursor: pointer;
			:disabled, &.disabled{
				opacity: .5;
			}
		}
		input{
			padding: 5px 10px;
			:disabled{
				opacity: .5;
			}
		}
		${renderCmsCss(globalStyles, { bodyTag: true })}
		.grecaptcha-badge{
			display: none;
		}
	`,
}
