import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const externalPrefixes = [
	`http://`,
	`https://`,
	`mailto:`,
	`tel:`,
]

export default function Link({ to, children, activeClassName, partiallyActive, ...props}){
	if(!to){
		return (
			<a href='#' {...props}>
				{children}
			</a>
		)
	}
	let internal = true
	for(let i = externalPrefixes.length; i--;){
		if(to.indexOf(externalPrefixes[i]) === 0){
			internal = false
			break
		}
	}

	if (internal) {
		if(to.charAt(0) !== `/`){
			to = `/${to}`
		}
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				{...props}
			>
				{children}
			</GatsbyLink>
		)
	}
	return (
		<a href={to || `#`} {...props}>
			{children}
		</a>
	)
}