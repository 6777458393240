module.exports = {
   "_createdAt": "2020-07-04T19:58:36Z",
   "_id": "singleton-shopifyPlugin",
   "_rev": "v2c9se7zSIXcDOzPw1Ku84",
   "_type": "shopifyPlugin",
   "_updatedAt": "2020-07-13T01:26:53Z",
   "enabled": true,
   "settings": {
      "accessToken": "1eb291ce3a36737c6791b1fd53572225",
      "appPassword": "b49fe44ea40be412694457666490d27200e6df137a3bba05c7999ccea41bd5be89f7b6a5731c18016a3605ea2dc61bba20d239bf0f203a410866dadb6a5b38d06e6393f5b6c56fe7cb427ee2b48aa680d7880b1e9c722f3f53b36a7ff204216d34af50fd676120eb6be43e55cbb3f2ea02125737bd2e98633f8ba413163e3a6a3a283b7d3b2b",
      "storeName": "winners-choice-strings",
      "themeId": "146636359"
   }
}