import React from 'react'
import ContactForm from '@app/contact-form/react'
import Alert from '@app/alert'
import pluginModules from '@rdk/config/react-content-modules'
import * as Components0 from './sanity-modules/accordion.js'; import * as Components1 from './sanity-modules/alert.js'; import * as Components2 from './sanity-modules/button-link.js'; import * as Components3 from './sanity-modules/columns.js'; import * as Components4 from './sanity-modules/contact-block.js'; import * as Components5 from './sanity-modules/copy-block.js'; import * as Components6 from './sanity-modules/search-input.js'; import * as Components7 from './sanity-modules/spacer.js'; var Components = [Components0, Components1, Components2, Components3, Components4, Components5, Components6, Components7]

const components = {
	...(pluginModules.default || pluginModules),
	default: ({ module }) => (
		<Alert type='error'>
			Module "{module._type}" not found
		</Alert>
	),
}
Components.forEach(component => {
	components[component.cmsId] = component.default
})
components.contactForm = ContactForm

export default function Modules({ module, modules, pageTitle }) {
	if(module) modules = [module]
	if (!modules) return null
	return (
		<div>
			{modules.map((module, index) => {
				const Component = components[module._type] || components.default
				return (
					<div key={index}>
						{/* <h1>{module._type}</h1> */}
						<Component module={module} pageTitle={pageTitle} />
					</div>
				)
			})}
		</div>
	)
}