import React from 'react'
import LayoutWrapper from '@app/layout-wrapper'
import SanityBlock from '@app/react-sanity-block-content'

export const cmsId = `copyBlock`

export default function CopyBlock(props) {
	const { copyBlockContent } = props.module
	return(
		<LayoutWrapper>
			<SanityBlock body={copyBlockContent} />
		</LayoutWrapper>
	)
}