import React from 'react'
import { css } from '@emotion/core'
import colors from '@data/sanity/colors.json'
import settings from '@data/sanity/wcHeaderSettings.json'
import get from 'lodash/get'
import { useUlysses } from '@smarterlabs/ulysses'

export default function CartIcon() {
	const { totalQuantity } = useUlysses()
	// const totalQuantity = 5
	if (!totalQuantity) return null
	return (
		<span css={styles.quantity}>
			<span css={styles.quantityInner}>{totalQuantity}</span>
		</span>
	)
}

const quantitySize = 18

const styles = {
	quantity: css`
		position: absolute;
		width: ${quantitySize}px;
		height: ${quantitySize}px;
		border-radius: 100%;
		top: -5px;
		right: -5px;
		border: 2px solid ${colors.black};
		color: ${colors.black};
		background: ${colors.white};
		font-size: 13px;
		font-weight: bold;
		background-color: ${get(settings, `backgroundColor.color.hex`, `#000`)};
	`,
	quantityInner: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding-left: 1px;
		padding-bottom: 1px;
	`,
}