import React, { useState, useEffect } from 'react'
import Lunr from 'lunr'
import index from '@data/lunr/index.json'
import store from '@data/lunr/store.json'
import Context from './context'

const lunr = Lunr.Index.load(index)

export default function Provider({ children }){
	const [query, setQuery] = useState(``)
	const [results, setResults] = useState([])
	const hasResults = results.length > 0

	useEffect(() => {
		if(!query){
			setResults([])
		}
		else {
			const lunrResults = lunr.search(query)
			const results = lunrResults.map(({ ref }) => store[ref])
			setResults(results)
		}
	}, [query])

	return(
		<Context.Provider value={{
			query,
			setQuery,
			results,
			hasResults,
		}}>
			{children}
		</Context.Provider>
	)
}