import React, { useState, useEffect, useReducer } from 'react'
import Context from './context'
import addToCart from './add-to-cart'
import checkout from './checkout'
import setQuantity from './set-quantity'
import remove from './remove'
import { mul } from 'sinful-math'

function eventsReducer(oldEvents, { type, label, fn, cb, data }) {

	// Add event
	if (type === `on`) {
		const events = { ...oldEvents }
		if (!events[label]) {
			events[label] = []
		}
		events[label].push(fn)
		return events
	}

	// Emit event
	if (type === `emit`) {
		if (!oldEvents[label]) {
			console.warn(`Emitting event label`, label, `not found`)
			cb()
			return oldEvents
		}
		runEvents(oldEvents[label], cb, data)
		return oldEvents
	}
}
async function runEvents(events, cb, data) {
	for (let i = 0; i < events.length; i++) {
		await events[i](data)
	}
	cb()
}

function addDays(date, days) {
	let result = new Date(date)
	result.setDate(result.getDate() + days)
	return result
}

export default function UlyssesProvider({
	children,
	plugins = [],
	localStorageKey = `ulysses-v1`,
	uid = `sku`,
}){
	const [lineItems, setLineItems] = useState([])
	const [pricing, setPricing] = useState({})
	const [inventory, setInventory] = useState({})
	const [totalQuantity, setTotalQuantity] = useState(0)
	const [totalPrice, setTotalPrice] = useState(0)
	const [isInitiating, setIsInitiating] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	const [cartIsOpen, setCartIsOpen] = useState(false)
	const [hasInit, setHasInit] = useState(false)
	const [events, eventsDispatch] = useReducer(eventsReducer, {})


	function on(label, fn) {
		eventsDispatch({ label, fn, type: `on` })
	}
	function emit(label, data) {
		return new Promise((resolve, reject) => {
			try {
				eventsDispatch({
					label,
					type: `emit`,
					cb: resolve,
					data,
				})
			}
			catch(err){
				reject(err)
			}
		})
	}


	// Runs when line items update
	useEffect(() => {
		// Update total quantity and price when line items update
		let newTotalQuantity = 0
		let newTotalPrice = 0
		for(let i = lineItems.length; i--;){
			newTotalQuantity += lineItems[i].quantity || 1
			newTotalPrice += mul(lineItems[i].price, lineItems[i].quantity) || 0
		}
		setTotalQuantity(newTotalQuantity)
		setTotalPrice(newTotalPrice)

		async function saveState() {
			// Save to localStorage
			if (hasInit) {
				let previousLineItems = lineItems
				let state = {
					lineItems,
					lastUpdated: Date.now(),
				}
				console.log(`saveState`, state)
				await ulysses.emit(`saveState`, {
					...ulysses,
					state,
				})
				if (previousLineItems === lineItems) {
					console.log(`Saving state`, state)
					localStorage.setItem(localStorageKey, JSON.stringify(state))
				}
			}
			setHasInit(true)
		}
		saveState()

	}, [lineItems])

	// Runs on init
	useEffect(() => {
		try{
			window.localStorage
		}
		catch(err){
			console.warn(`Local storage not supported`)
			return
		}
		
		// Load from localStorage
		let state = localStorage.getItem(localStorageKey)
		if (!state){
			console.log(`localStorage state not found`)
			setIsLoading(false)
			setIsInitiating(false)
			return
		}
		state = JSON.parse(state)

		// Don't use if cart is probably expired
		if(state.lastUpdated){
			let now = new Date()
			let expiration = addDays(state.lastUpdated, 13)
			if(now > expiration){
				state = false
			}

		}

		if (!state || !state.lastUpdated){
			console.log(`localStorage state not found`)
			setIsLoading(false)
			setIsInitiating(false)
			return
		}
		console.log(`Found localStorage state`, state)
		async function loadState(){
			console.log(`loadState with key`, localStorageKey)
			try{
				await ulysses.emit(`loadState`, { ...ulysses, state })
			}
			catch(err){
				console.log(`Loading cart state failed, removing localStorage state`)
				localStorage.removeItem(localStorageKey)
				console.error(err)
			}
			if (state.lineItems) {
				console.log(`loaded state.lineItems`, state.lineItems)
				setLineItems(state.lineItems)
			}
			setIsLoading(false)
			setIsInitiating(false)
		}
		loadState()
	}, [])

	// Exposed via useUlysses
	const ulysses = {
		uid,
		lineItems,
		setLineItems,
		pricing,
		setPricing,
		inventory,
		setInventory,
		totalQuantity,
		totalPrice,
		plugins,
		on,
		emit,
		isLoading,
		setIsLoading,
		isInitiating,
		setIsInitiating,
		cartIsOpen,
		events,
		setCartIsOpen,
	}
	ulysses.addToCart = item => addToCart({ item, ...ulysses })
	ulysses.checkout = () => checkout(ulysses)
	ulysses.setQuantity = (productId, amount) => setQuantity({ productId, amount, ...ulysses })
	ulysses.adjustQuantity = (productId, amount) => setQuantity({ productId, amount: cur => cur + amount, ...ulysses })
	ulysses.remove = productId => remove({ productId, ...ulysses })

	return(
		<Context.Provider value={ulysses}>
			{children}
		</Context.Provider>
	)
}