import React from 'react'
import { css } from '@emotion/core'
import get from 'lodash/get'
import Link from '@app/link'
import BgImg from '@app/responsive-image-sanity/background'
import LayoutWrapper from '@app/layout-wrapper'
import SanityBlock from '@app/react-sanity-block-content'
import settings from '@data/sanity/heroSettings.json'
import renderCss from '@app/sanity-style-fields/css'

export default function Hero(props){
	props = props.module
	const copy = get(props, `copyBlockContent`)


	let component = (
		<div css={styles.copy}>
			<LayoutWrapper>
				{!!copy && (
					<SanityBlock body={copy} />
				)}
			</LayoutWrapper>
		</div>
	)


	if (props.background === `backgroundImage`) {
		const bg = get(props, `backgroundImage.asset`)
		component = (
			<div css={styles.container}>
				<BgImg asset={bg}>
					{component}
				</BgImg>
			</div>
		)
	}
	else {
		const { r, g, b, a } = get(props, `backgroundColor.rgb`, {})
		let background
		if (r) {
			background = `rgba(${r}, ${g}, ${b}, ${a})`
		}
		component = (
			<div style={{ background }} css={styles.container}>{component}</div>
		)
	}

	const constrain = props.constrain ? `constrain` : ``

	if(props.buttonText || props.children){
		return (
			<div className={constrain}>
				{component}
			</div>
		)
	}

	return (
		<Link to={props.link} className={constrain} css={styles.containerLink}>
			{component}
		</Link>
	)

}

const styles = {
	container: css`
		> *{
			position: relative;
			padding: 30px 0;
			min-height: 50vh;
			background-size: cover;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			${renderCss(settings)}
		}
	`,
	containerLink: css`
		text-decoration: none;
	`,
	image: css`
		max-width: 200px;
	`,
	button: css`
		border-width: 2px;
		border-style: solid;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		display: inline-block;
		padding: 5px 10px;
		:hover, :focus, :active{
			opacity: .6;
		}
	`,
	img: css`
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	`,
	copy: css`
		text-align: center;
	`,
}