import React from 'react'
import LayoutWrapper from '@app/layout-wrapper'
import BreadCrumbs from './bread-crumbs'

export default function WrappedBreadCrumbs(props) {
	return(
		<LayoutWrapper>
			<BreadCrumbs {...props} />
		</LayoutWrapper>
	)
}