import React from 'react'
import { css } from '@emotion/core'

export default function LayoutWrapper({ children }){
	return(
		<div css={styles.wrapper}>
			{children}
		</div>
	)
}

const styles = {
	wrapper: css`
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 10px;
	`,
}