import React, { useState } from 'react'
import Link from '@app/react-sanity-dynamic-link'
import { css } from '@emotion/core'
import fonts from '@data/sanity/fonts.json'
import ClickOutside from '@app/detect-click-outside'
import settings from '@data/sanity/wcHeaderSettings.json'
import get from 'lodash/get'

const separator = settings.separator || {}

export default function NavMenu({ links, level }) {
	if(!level) level = 1
	const isTopLevel = level === 1
	return (
		<ul css={[styles.list, isTopLevel ? styles.topLevel : styles.subLevel]}>
			{links.map((link, index) => (
				<NavItem key={index} level={level} link={link} isTopLevel={isTopLevel} />
			))}
		</ul>
	)
}

function NavItem({ link, level, isTopLevel }){
	const hasSubnav = link.links && link.links.length > 0
	const [dropdownOpen, setDropdownOpen] = useState(false)

	function clickHandler(e){
		e.preventDefault()
		setDropdownOpen(!dropdownOpen)
	}

	return (
		<ClickOutside onClick={() => {
			setDropdownOpen(false)
		}}>
			{ref => (
				<li css={[styles.navItem, isTopLevel && styles.topLevelItem]} ref={ref}>
					{!!link && (
						<Link
							{...link}
							onClick={hasSubnav && clickHandler}
							css={[styles.link, isTopLevel ? (separator.active ? styles.topLevelLinkLine : null) : styles.subLevelLink]}
						>
							{link.title}
						</Link>
					)}
					{hasSubnav && (
						<div css={[styles.submenu, dropdownOpen && styles.openSubmenu]}>
							<NavMenu links={link.links} level={level + 1} />
						</div>
					)}
				</li>
			)}
		</ClickOutside>
	)
}

const styles = {
	topLevelLinkLine: css`
		:before{
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: ${get(separator, `color.color.hex`, `#fff`)};
			margin: 20px 0 10px auto;
		}
	`,
	list: css`
		list-style-type: none;
		padding: 0;
		margin: 0;
		text-align: right;
		a{
			display: block;
		}
	`,
	navItem: css`
		position: relative;
	`,
	link: css`
		padding: 0 10px;
		text-transform: uppercase;
		${fonts.primary};
		letter-spacing: .05em;
		font-size: 1.3em;
		:hover{
			opacity: .6;
		}
	`,
	submenu: css`
		display: none;
		/* position: absolute; */
		z-index: 1;
	`,
	openSubmenu: css`
		display: block;
	`,
	topLevel: css`
		margin-top: 30px;
		> *{
			margin: 10px 0;
		}
	`,
}