import find from 'lodash/find'
import clearEmpty from './clear-empty-line-items'

export default async function setQuantity({
	productId,
	amount,
	lineItems,
	setLineItems,
	emit,
	uid,
	setIsLoading,
}) {
	let lineItem
	let newLineItems = lineItems.map(item => {
		return {...item}
	})
	if(!uid) {
		console.log(`"${uid}" is required to set quantity for line item "${productId}"`)
	}
	setIsLoading(true)
	lineItem = find(newLineItems, o => o[uid] === productId)
	if (!lineItem) {
		console.error(`Line item "${productId}" not found`)
		return setIsLoading(false)
	}

	if(typeof amount === `function`){
		lineItem.quantity = amount(lineItem.quantity)
	}
	else {
		lineItem.quantity = amount
	}


	// Run events from plugins
	console.log(`Emitting setquantity event...`)
	try {
		await emit(`updateLineItems`, { lineItem })
	}
	catch (err) {
		console.error(err)
	}
	console.log(`Emitted setquantity event`)

	clearEmpty(newLineItems)
	setLineItems(newLineItems)
	setIsLoading(false)
}