import React from 'react'
import { css } from '@emotion/core'
import LayoutWrapper from '@app/layout-wrapper'
import Alert from '@app/alert'
import SanityBlock from '@app/react-sanity-block-content'

export const cmsId = `alert`

export default function AlertCMSModule(props) {
	const { type, copyBlockContent } = props.module
	return(
		<LayoutWrapper>
			<div css={styles.wrapper}>
				<Alert type={type}>
					<SanityBlock body={copyBlockContent} />
				</Alert>
			</div>
		</LayoutWrapper>
	)
}

const styles = {
	wrapper: css`
		p{
			margin: 0;
		}
	`,
}