import React, { useEffect, useRef } from 'react'

// Detects clicks outside of itself and inside of links
// Used for closing nav menus when a click happens outside and on links
export default function DetectClickOutside({ children, onClick, ...props }){
	const el = useRef()

	useEffect(() => {
		function clickHandler(e) {
			let isLink = (e.target.tagName == `A` && e.target.href && e.target.href != `#`)
			let href
			if(isLink){
				href = e.target.getAttribute(`href`)
			}
			if (!el.current.contains(e.target) || (isLink && href && href != `#`)) {
				onClick(e)
			}
		}
		document.addEventListener(`click`, clickHandler)
		return () => {
			document.removeEventListener(`click`, clickHandler)
		}
	}, [el, onClick])

	if(typeof children == `function`){
		return children(el)
	}

	return (
		<div {...props} ref={el}>
			{children}
		</div>
	)
}