import React from 'react'
import get from 'lodash/get'
import { css } from '@emotion/core'
import settings from '@data/sanity/imageHeaderSettings.json'
import BgImg from '@app/responsive-image-sanity/background'
import { renderStyles } from '@app/sanity-style-fields/css'

export default function ImageHeader(props){
	const module = props.module || {}
	return (
		<section className='imageHeaderModule' css={styles.container}>
			<div css={styles.image}>
				<BgImg asset={get(module, `backgroundImage.asset`)} />
			</div>
			<h1 css={styles.header}>{module.string}</h1>
		</section>
	)
}

const smallBp = 900
const largeBp = 1200
const third = 100 / 3

const styles = {
	container: css`
		position: relative;
		min-height: 40vh;
	`,
	header: css`
		position: absolute;
		padding: 10px 30px;
		text-align: center;
		bottom: 0;
		right: 0;
		left: 0;
		margin: 0;
		${renderStyles(settings.titleStyles)}
	`,
	copy: css`
		position: relative;
		text-align: center;
		padding: 20px;
		@media(min-width: ${smallBp}px){
			background-color: ${get(settings, `bgColor.color.hex`, `#000`)};
			width: 50%;
		}
		@media(min-width: ${largeBp}px){
			width: ${third}%;
		}
	`,
	image: css`
		> *{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-size: cover;
			background-repeat: no-repeat;
		}
	`,
}