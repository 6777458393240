import get from 'lodash/get'

const els = {
	linkStyles: `a, .a`,
	buttonStyles: `button, .button`,
	h1Styles: `h1, .h1`,
	h2Styles: `h2, .h2`,
	h3Styles: `h3, .h3`,
	h4Styles: `h4, .h4`,
	h5Styles: `h5, .h5`,
	h6Styles: `h6, .h6`,
	paragraphStyles: `p, .p`,
}

export default function createCss(config, options = {}){
	let css = []
	if(config.bodyStyles){
		if (!options.bodyTag) {
			css.push(sanityStyles(`bodyStyles`, config))
		}
		else{
			css.push(`body{ ${sanityStyles(`bodyStyles`, config)} }`)
		}
	}

	for(let i in els){
		if(config[i]){
			css.push(`${els[i]}{ ${sanityStyles(i, config)} }`)
		}
	}
	css = css.join(`\n`)
	return css
}


function sanityStyles(name, styles) {
	if (!name || !styles[name] || !styles[name].length) return ``
	return renderStyles(styles[name])
}

const stateFields = [
	`color`,
	`backgroundColor`,
	`borderColor`,
	`css`,
]

function createCssLines(obj){
	const lineObj = {
		'font-family': get(obj, `fontFamily.cssValue`),
		'font-size': obj.fontSize ? `${obj.fontSize}em` : undefined,
		'color': get(obj, `color.color.hex`),
		'background-color': get(obj, `backgroundColor.color.hex`),
		'border-width': obj.borderWidth ? `${obj.borderWidth}px` : undefined,
		'border-color': get(obj, `borderColor.color.hex`),
	}
	const lines = []
	for (let key in lineObj) {
		const value = lineObj[key]
		if (value !== undefined) {
			lines.push(`${key}:${value};`)
		}
	}
	return lines
}

export function renderStyles(styles) {
	if(!styles) styles = []
	const lines = []
	const code = []
	styles.forEach(obj => {

		// Create base styles
		lines.push(...createCssLines(obj))



		// TODO: State styles
		const hoverState = obj.hoverState || {}
		const activeState = obj.activeState || {}
		const focusState = obj.activeState || {}
		let hasHoverState = false
		let hasActiveState = false
		let hasFocusState = false
		stateFields.forEach(key => {
			if (hoverState[key] !== undefined) {
				hasHoverState = true
			}
			if (activeState[key] !== undefined) {
				hasActiveState = true
			}
			if (focusState[key] !== undefined) {
				hasFocusState = true
			}
		})
		if(hasHoverState){
			lines.push(`:hover{`, ...createCssLines(hoverState), `}`)
			const css = get(hoverState, `css.code`)
			if(css){
				code.push(`:hover{${css}}`)
			}
		}
		if (hasActiveState){
			lines.push(`:active{`, ...createCssLines(activeState), `}`)
			const css = get(activeState, `css.code`)
			if (css) {
				code.push(`:active{${css}}`)
			}
		}
		if (hasFocusState){
			lines.push(`:focus{`, ...createCssLines(focusState), `}`)
			const css = get(focusState, `css.code`)
			if (css) {
				code.push(`:focus{${css}}`)
			}
		}



		// Add CSS code
		code.push(get(obj, `css.code`, ``))
	})
	return `
		${lines.join(`\n`)}
		${code.join(`\n`)}
	`
}