import React from 'react'
import Player from 'react-player'
import { css } from '@emotion/core'
import FaTimes from '@meronex/icons/fa/FaTimes'
import DetectClickOutside from '@app/detect-click-outside'

export default function VideoModal(props) {
	if (!props.isOpen) return null
	return (
		<div css={styles.modal}>
			<button css={styles.closeButton}>
				<FaTimes />
			</button>
			<DetectClickOutside onClick={props.onClose} css={styles.videoContainer}>
				<Player
					url={props.link}
					width='100%'
					height='100%'
					config={{
						youtube: {
							playerVars: {
								rel: 0,
								controls: 1,
								modestbranding: 1,
								autoplay: 1,
								showinfo: 0,
							},
						},
					}}
				/>
				<style dangerouslySetInnerHTML={{ __html: `body{overflow:hidden}` }} />
			</DetectClickOutside>
		</div>
	)

}

const modalMargin = 80

const styles = {
	modal: css`
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .8);
		z-index: 999;
	`,
	videoContainer: css`
		position: absolute;
		top: ${modalMargin}px;
		left: ${modalMargin}px;
		right: ${modalMargin}px;
		bottom: ${modalMargin}px;
	`,
	closeButton: css`
		outline: 0;
		background: transparent;
		color: #fff;
		font-weight: bold;
		position: absolute;
		border: 0;
		font-size: 1.7em;
		cursor: pointer;
		top: 20px;
		right: 20px;
		:hover, :focus, :active{
			opacity: .7;
		}
	`,
}