import React, { useState } from 'react'
import Link from '@app/link'
import get from 'lodash/get'
import VideoModal from '@app/react-video-modal'

export default function SanityDynamicLink(props) {
	const link = get(props, `link`, {})
	const type = get(link, `linkType`)
	const newTab = get(link, `newTab`, false)
	let url

	if (type === `internal`) {
		url = get(link, `internalLink.slug.current`)
		if (!newTab) {
			return (
				<Link
					className={props.className}
					onClick={props.onClick || null}
					to={url}
				>
					{props.text || props.children}
				</Link>
			)
		}
	}

	else if (type === `media`) {
		url = get(link, `mediaLink`)
		if (!newTab) {
			return (
				<VideoButton
					className={props.className}
					link={url}
					text={props.text || props.children}
				/>
			)
		}
	}

	else if (type === `external`) {
		url = get(link, `externalLink`)
		if (!newTab) {
			return (
				<a
					className={props.className}
					rel="noreferrer"
					onClick={props.onClick || null}
					href={url}
				>
					{props.text || props.children}
				</a>
			)
		}
	}

	if(newTab){
		return (
			<a
				className={props.className}
				rel="noreferrer"
				href={url}
				onClick={props.onClick || null}
				target='_blank'
			>
				{props.text || props.children}
			</a>
		)
	}

	return (
		<a
			className={props.className}
			rel="noreferrer"
			href='#'
			onClick={props.onClick || null}
			target='_blank'
		>
			{props.text || props.children}
		</a>
	)
}

function VideoButton({ link, text, className }) {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<>
			<a href='#' className={className} onClick={e => {
				e.preventDefault()
				setIsOpen(true)
			}}>{text}</a>
			<VideoModal isOpen={isOpen} link={link} onClose={() => setIsOpen(false)} />
		</>
	)
}